import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

import './components/about-me/about-me.js';
import './components/social-media/social-media.js';
import './components/profile-img/profile-img.js';

import { highlightWhiteBg } from './common/styles.js';

const resume = new URL('../assets/resume.pdf', import.meta.url).href;

@customElement('app-shell')
export class AppShell extends LitElement {
  static override styles = [
    highlightWhiteBg,
    css`
      :host {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 1.5vmin);
        color: white;
        margin: 0 auto;
        text-align: center;
      }

      main {
        max-width: 800px;
        margin: 1.5rem 8px 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
      }

      .name {
        font-family: 'Space Grotesk', 'Helvetica Neue', Arial, sans-serif;
        font-size: min(10vw, 3.5rem);
        font-weight: 600;
        text-decoration: none;
        margin: 0;
        padding: 0;
      }

      social-media {
        width: 100%;
        margin-top: 4px;
      }

      .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        width: 175px;
      }

      .button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: auto;
        border-radius: 10px;
        transition: all 0.2s ease-in-out;
        vertical-align: middle;
        text-align: center;
        font-size: 1.3rem;
        width: 100%;
        height: 3rem;
        background-color: var(--dark-fg);
        border: 2px solid var(--black);
        & a {
          color: inherit;
          text-decoration: none;
          height: 100%;
          width: 100%;
          padding: 12px;
          &:hover {
            font-weight: 600;
          }
        }
        &:hover {
          transform: scale(108%);
          background-color: var(--coyote-brown);
          color: var(--black);
        }
      }

      .app-footer {
        font-size: calc(12px + 0.5vmin);
        align-items: center;
      }

      @media screen and (max-width: 480px) {
        main {
          margin: 8px;
        }
        .name {
          font-size: 40px;
        }
      }
    `,
  ];

  render() {
    return html`
      <main>
        <profile-img></profile-img>
        <h1 class="name">Colin Valentini</h1>
        <about-me></about-me>
        <div class="button-container">
          <div class="button" aria-label="Checkout my Formula 1 app">
            <a href="/f1">F1</a>
          </div>
          <div class="button" aria-label="Checkout my resume">
            <a href=${resume}>Resume</a>
          </div>
          <social-media></social-media>
        </div>
      </main>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'app-shell': AppShell;
  }
}
