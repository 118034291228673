import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

import { highlightWhiteBg } from '../../common/styles.js';

// NOTE: @web/rollup-plugin-import-meta-assets only supports string literals
// in the first argument to URL(...). You CANNOT use string expressions.
// This is not documented and fucking infuriating.
// See https://github.com/modernweb-dev/web/issues/2402.
const PROFILE_IMG_SRC = new URL(
  '../../../assets/images/portrait.webp',
  import.meta.url
).href;
const PROFILE_IMG_SRC_SET = new Map([
  [
    '960w',
    new URL('../../../assets/images/portrait.webp', import.meta.url).href,
  ],
  [
    '800w',
    new URL('../../../assets/images/portrait_800x800.webp', import.meta.url)
      .href,
  ],
  [
    '600w',
    new URL('../../../assets/images/portrait_600x600.webp', import.meta.url)
      .href,
  ],
  [
    '400w',
    new URL('../../../assets/images/portrait_400x400.webp', import.meta.url)
      .href,
  ],
  [
    '200w',
    new URL('../../../assets/images/portrait_200x200.webp', import.meta.url)
      .href,
  ],
]);

@customElement('profile-img')
export class ProfileImg extends LitElement {
  static override styles = [
    highlightWhiteBg,
    css`
      :host {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
      }

      img {
        width: 150px;
        height: auto;
        border-radius: 5px 50px 5px 50px;
        transition: all 0.2s ease-in-out;
      }

      @media screen and (max-width: 480px) {
        :host {
          margin-top: 5px;
        }
        img {
          width: 120px;
          height: auto;
        }
      }
    `,
  ];

  override render() {
    const src = PROFILE_IMG_SRC;
    return html`
      <img
        alt="Self portrait"
        src=${src}
        srcset="${ProfileImg.profileImgSrcset()}"
        height="150px"
        width="150px"
      />
    `;
  }

  private static profileImgSrcset(): string {
    const srcs: string[] = [];
    PROFILE_IMG_SRC_SET.forEach((href: string, size: string) => {
      srcs.push(`${href} ${size}`);
    });
    return srcs.join(', ');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'profile-img': ProfileImg;
  }
}
