import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

import { highlightWhiteBg } from '../../common/index.js';

@customElement('about-me')
export class AboutMe extends LitElement {
  // https://codepen.io/jadlimcaco/pen/ExjGrqJ
  static styles = [
    highlightWhiteBg,
    css`
      :host {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        transition: all 0.2s ease-in-out;
        max-width: 95%;
        margin: 0;
        padding: 0;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: 18px;
      }
      a {
        color: white;
      }
      @media screen and (max-width: 480px) {
        p {
          font-size: 16px;
        }
      }
    `,
  ];

  render() {
    return html`
      <p>
        I'm a backend software engineer currently working in the technology
        industry, with prior experience in the financial services sector.
      </p>
      <p>
        I hold a bachelor's degree in applied mathematics from Columbia
        University. Prior to attending college, I was a United States Marine for
        four years serving with
        <a
          href="https://www.1stmardiv.marines.mil/Units/7TH-MARINE-REGT/1st-Battalion/"
          target="_blank"
          rel="noopener noreferrer"
          >1st battalion, 7th Marine regiment.</a
        >
      </p>
      <p>
        I'm a fast learner and infinitely curious. I have a passion for
        producing clean, finished products that users love.
      </p>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'about-me': AboutMe;
  }
}
